body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
    font-size: 14px;
    background: #f5f6f5;
    min-width: 960px;
}


a {
    text-decoration: none;
    color: inherit;
}

a:active {
    color: inherit;
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    background: #f5f6f5;
    width: 100%;
    margin: 0;
    padding: 0 10px;
    /* padding:0 4.287rem; */
    width: 100%;
    height: 62px;
    line-height: 62px;
    z-index: 2;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
}

.main {
    margin-top: 62px;
}

.anchor {
    width: 100%;
    height: 0;
    display: block;
}

.h-icon {
    width: 70px;
    height: 49px;
    margin-top: 4px;
    float: left;
}

.h-right {
    float: right;
}

.h-right::after {
    content: '';
    display: block;
    clear: both;
}

.h-right ul {
    list-style: none;
}

.h-right ul li {
    margin-left: 40px;
    margin-left: 2.857rem;
    color: black;
    float: left;
}

.h-right li a.active {
    color: #4A90E2;
}

.footer {
    height: 62px;
    line-height: 62px;
    text-align: center;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    padding: 12px auto;
    color: #4C4C4C;
    font-size: 16px;
    font-weight: bold;
}

.body-carousel {
    background: url(/static/media/1.7b592f87.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 480px;
}

.body-carousel::after {
    content: '';
    display: block;
    clear: both;
}

.body-carousel-left {
    width: 55%;
    float: left;
    height: 100%;
    text-align: center;
}

.body-carousel-left div {
    position: relative;
    padding-top: 9.57rem;
    text-align: center;
}

.body-carousel-left div::after {
    content: '';
    display: block;
    clear: both;
}

.body-carousel-left h1 {
    color: #fff;
    font-size: 4.29rem;
}

.body-carousel-left span {

}

.body-carousel-right {
    float: right;
    width: 45%;
    height: 100%;
    text-align: center;
}

.body-carousel-right img {
    height: 320px;
    width: auto;
    margin-top: 80px;
    float: right;
    margin-right: 20px;
}

.body-content {
    padding: 20px;
}

.body-content h1 {
    font-size: 20px;
    color: #4A90E2;
    text-align: left;
}

.body-content p {
    text-align: left;
    margin-top: 20px;
    line-height: 21px;
    color: #4C4C4C;
}

.body-ada {
    background: url(/static/media/2.e3774c2b.png);
    background-size: cover;
    height: 322px;
    height: 23rem;
    text-align: center;
    font-size: 20px;
    font-size: 1.429rem;
    color: #fff;
    padding: 20px 60px;
    padding: 1.429rem 4.286rem;
}

.body-ada h1 {
    font-size: 30px;
    font-size: 2.143rem;
    color: #fff;
}

.body-ada span {
    margin-top: 15px;
    margin-top: 1.071rem;
    margin-bottom: 15px;
    margin-bottom: 1.071rem;
    display: block;
}

.body-ada p {
    text-align: center;
    line-height: 24px;
    line-height: 1.714rem;
}

.body-ada .span-blue {
    font-size: 20px;
    font-size: 1.428rem;
    margin-top: 40px;
    margin-top: 2.857rem;
}

.body-map {
    height: 598px;
    height: 42.714rem;
    width: 100%;
    position: relative;
    background: url(/static/media/map.2384e538.png);
    background-size: cover;
}

.body-map-left {
    position: absolute;
    width: 400px;
    width: 28.571rem;
    z-index: 1;
    /* left:44px; */
    padding-left: 15px;
    padding-left: 1.071rem;
    font-size: 16px;
    font-size: 1.143rem;
    color: #4C4C4C;
    background: rgba(255, 255, 255, .5);
}

#myMap {
    width: 100%;
    height: 100%;
}

#myMap1 {
    width: 100%;
    height: 100%;
}

#myMap1 a {
    display: block;
    height: 100%;
    width: 100%;
}

.body-map-left h1 {
    margin-top: 60px;
    margin-top: 4.286rem;
    font-size: 30px;
    font-size: 2.143rem;
    text-align: left;
}

.body-map-left .bma {
    display: block;
    width: 100%;
}

.clear::after {
    display: block;
    content: '';
    clear: both;
}

.body-map-left .bma .icon {
    width: 21px;
    float: left;
    height: 26px;
    margin-top: 10px;
}

.body-map-left .bma .text {
    line-height: 30px;
    line-height: 2.143rem;
    text-align: left;
    margin-left: 40px;
    margin-left: 2.857rem;
}

.body-map-left .code {
    width: 215px;
    width: 15.367rem;
    height: 215px;
    height: 15.357rem;
    background: url(/static/media/2Dcode.28539888.png);
    background-size: cover;
}


.span-blue {
    background: rgba(74, 144, 226, .5);
    color: white;
    font-size: 50px;
    font-size: 3.57rem;
    margin-top: 20px;
    padding: 10px 20px;
    border-radius: 20px;
}

.btn-blue {
    background: #4A90E2;
    border-radius: 4px;
    color: white;
    padding: 3px 4px;
}

.btn-blue:active, .btn-blue:hover {
    color: white;
}

.App {
    text-align: center;
}

.App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
}

@media (min-width: 768px) {
    .container {
        width: 700px;

    }
}

@media (min-width: 992px) {
    .container {
        width: 900px;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1100px;
    }
}


@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}


@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@media only screen and (max-width: 600px) {
    body {
        width: 100%;
        min-width: auto;
    }

    .header {
        padding: 0 .5rem;
    }

    .h-right {
        width: 100px;
    }

    .footer {
        width: 80%;
        line-height: 24px;
    }

    .body-carousel {
        height: 22.3rem
    }

    .body-carousel-right img {
        width: 100%;
        height: auto;
        margin-top: 5.7rem;
    }
}

.m-l {
    margin-left: 20px;
}

